import { useNavigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Splash } from '../pages/Splash';

export const ProtectedRoute = ({
  user,
  children,
}: {
  user: User | null;
  children: any;
}) => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) nav('/login');
    setIsLoading(false);
  }, []);

  return isLoading ? <Splash /> : children;
};
