import React, { useEffect } from 'react';

export const Admin = () => {
  useEffect(() => {
    window.location.href =
      'https://rowy.app/p/suited-membership/table/reservations#searchPage=0';
  }, []);

  return <h4>Redirecting...</h4>;
};
