import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { User, UserEntity } from '../types/user';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
auth.useDeviceLanguage();

export const db = getFirestore(app);

export class FireStore {
  static getUserData = async (uid: string): Promise<null | User> => {
    const ref = doc(db, 'users', uid);
    if (!ref) return null;

    const snapshot = await getDoc(ref);
    if (!snapshot.exists()) return null;

    const user = new User(snapshot.data() as UserEntity);
    user.setId(uid);
    return user;
  };

  static setUserData = async (
    uid: string,
    { phoneNumber, name }: { phoneNumber: string; name: string }
  ): Promise<void> => {
    const ref = doc(db, 'users', uid);
    await setDoc(ref, { phoneNumber, name });
    return;
  };
}
