import { Container, VStack, Box } from '@chakra-ui/layout';
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input';
import { Text, Center } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/button';
import {
  browserLocalPersistence,
  setPersistence,
  GoogleAuthProvider,
  signInWithRedirect,
  onAuthStateChanged,
} from 'firebase/auth';
import { FireStore, auth } from '../providers/firebase';
import { useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { Splash } from './Splash';

export const Login = () => {
  const nav = useNavigate();
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
  provider.setCustomParameters({ prompt: 'select_account' });

  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [step, setStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Get user data
        const userData = await FireStore.getUserData(user.uid);
        if (userData) {
          if (userData.phoneNumber) {
            nav('/reservations');
            return;
          }
        }
        setIsLoading(false);
        setStep(1);
        return;
      }
      setIsLoading(false);
      return;
    });

    return unsubscribe;
  }, []);

  const handleLogin = async () => {
    await setPersistence(auth, browserLocalPersistence);
    await signInWithRedirect(auth, provider);
  };

  const handlePhoneNumberInput = (value: string) => {
    value = value.replace('-', '');
    if (!Number.isInteger(+value)) return;
    if (value.length > 8) return;
    if (value.length === 8) setIsValidPhoneNumber(true);
    else setIsValidPhoneNumber(false);

    setPhoneNumber(value);
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/(\d{4})(\d{4})/, '$1-$2');
  };

  const registerUserData = async () => {
    await FireStore.setUserData(auth.currentUser!.uid, {
      phoneNumber: '010' + phoneNumber,
      name,
    });
    nav('/reservations');
  };

  return isLoading ? (
    <Splash />
  ) : (
    <Container centerContent height="100%" justifyContent="center">
      <Box color="white">
        <Text fontSize="42px" fontWeight={700}>
          PAIRED
        </Text>
        <Text fontSize="16px">reservation system</Text>
      </Box>
      <Box height="20px" />
      {
        [
          <Button
            width="100%"
            leftIcon={<FcGoogle />}
            maxWidth="200px"
            onClick={() => handleLogin()}
          >
            <Center>
              <Text>Sign in with Google</Text>
            </Center>
          </Button>,
          <VStack>
            <InputGroup>
              <Input
                type="name"
                placeholder="Write your name"
                color="white"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon children="010" color="#101727" />
              <Input
                type="tel"
                placeholder="1234-5678"
                color="white"
                value={formatPhoneNumber(phoneNumber)}
                onChange={(e) => handlePhoneNumberInput(e.target.value)}
              />
            </InputGroup>
            <Button
              id="sign-in-button"
              variant="solid"
              width="100%"
              isDisabled={!isValidPhoneNumber || !name}
              onClick={() => registerUserData()}
            >
              Register
            </Button>
          </VStack>,
        ][step]
      }
    </Container>
  );
};
