export interface UserEntity {
  phoneNumber: string;
  name: string;
}

export class User {
  id?: string;
  phoneNumber: string;
  name: string;

  constructor({ phoneNumber, name }: UserEntity) {
    this.phoneNumber = phoneNumber;
    this.name = name;
  }

  setId(id: string) {
    this.id = id;
  }
}
