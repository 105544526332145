import { Image } from '@chakra-ui/image';
import { Box, Container } from '@chakra-ui/layout';
import { User } from 'firebase/auth';
import { useEffect } from 'react';

export const Splash = () => {
  return (
    <Container centerContent>
      <Box maxW="60%" minW="140px">
        <Image src="logo.png" />
      </Box>
    </Container>
  );
};
