import { Box, Container, Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';
import { forwardRef } from '@chakra-ui/system';
import { addMonths } from 'date-fns';
import { useNumberInput } from '@chakra-ui/number-input';
import { Input } from '@chakra-ui/input';
import { Select, Textarea } from '@chakra-ui/react';
import { onAuthStateChanged } from 'firebase/auth';
import { FireStore, auth, db } from '../providers/firebase';
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { Reservation, ReservationEntity } from '../types/reservation';
import { User } from '../types/user';

const timeOptions: { text: string; hour: number; minutes: number }[] = [
  {
    text: '19:00',
    hour: 19,
    minutes: 0,
  },
  {
    text: '19:30',
    hour: 19,
    minutes: 30,
  },
  {
    text: '20:00',
    hour: 20,
    minutes: 0,
  },
  {
    text: '20:30',
    hour: 20,
    minutes: 30,
  },
  {
    text: '21:00',
    hour: 21,
    minutes: 0,
  },
  {
    text: '21:30',
    hour: 21,
    minutes: 30,
  },
  {
    text: '22:00',
    hour: 22,
    minutes: 0,
  },
  {
    text: '22:30',
    hour: 22,
    minutes: 30,
  },
  {
    text: '23:00',
    hour: 23,
    minutes: 0,
  },
  {
    text: '23:30',
    hour: 23,
    minutes: 30,
  },
];

export const ReservationPage = () => {
  const nav = useNavigate();

  const { reservationId } = useParams();

  const [memberCount, setMemberCount] = useState(1);
  const [targetDate, setTargetDate] = useState(new Date());
  const [targetTime, setTargetTime] = useState(timeOptions[0]);
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [user, setUser] = useState<User>();

  useEffect(() => {
    window.scrollTo(0, 0);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Get user data
        const userData = await FireStore.getUserData(user.uid);
        if (!userData) {
          nav('/login');
          return;
        }
        setUser(userData);
        userData.name && setName(userData.name);

        if (reservationId) {
          const ref = doc(db, 'reservations', reservationId);
          const snapshot = await getDoc(ref);
          const reservation = new Reservation(
            snapshot.data() as ReservationEntity
          );

          setMemberCount(reservation.memberCount);
          setName(reservation.name);
          reservation.comment && setComment(reservation.comment);
          handleDateChange(reservation.targetDate);
          handleTimeChange(reservation.targetTime);
        }
      } else {
        nav('/login');
      }
    });

    return unsubscribe;
  }, []);

  const handleDateChange = (date: Date) => {
    const parsedDate = date;
    parsedDate.setHours(0);
    parsedDate.setMinutes(0);
    parsedDate.setSeconds(0);
    setTargetDate(parsedDate);
  };

  const handleTimeChange = (timeText: string) => {
    const timeOpt = timeOptions.find((opt) => opt.text === timeText);
    setTargetTime(timeOpt!);
  };

  const handleSubmit = async () => {
    const combinedDate = targetDate;
    combinedDate.setHours(targetTime.hour);
    combinedDate.setMinutes(targetTime.minutes);

    try {
      if (reservationId) {
        await updateDoc(doc(db, 'reservations', reservationId), {
          memberCount,
          name,
          comment,
          targetDate: Timestamp.fromDate(targetDate),
        });
      } else {
        await addDoc(collection(db, 'reservations'), {
          memberCount,
          targetDate: Timestamp.fromDate(targetDate),
          phone: user!.phoneNumber,
          name,
          comment,
          uid: user!.id,
        });
      }

      nav('/reservations');
    } catch (e) {
      throw e;
    }
  };

  const handleCancelation = async () => {
    if (!reservationId) {
      throw new Error('invalid access');
    }
    try {
      await deleteDoc(doc(db, 'reservations', reservationId));
      nav('/reservations');
    } catch (e) {
      throw e;
    }
  };

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      value: memberCount,
      min: 1,
      max: 100,
      precision: 0,
      onChange: (_, v) => setMemberCount(v),
    });

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <Button onClick={onClick} ref={ref}>
      {value}
    </Button>
  ));

  return (
    <Container
      centerContent
      width="100%"
      height="100%"
      paddingLeft={0}
      paddingRight={0}
    >
      <Box maxW="200px" paddingBottom="20px"></Box>
      <Flex
        justifyContent="center"
        flexGrow="1"
        alignItems="center"
        width="100%"
        maxWidth="300px"
      >
        <VStack width="100%" spacing={8}>
          <HStack width="100%" justifyContent="space-between">
            <Text color="white">방문 인원</Text>
            <Box maxWidth="150px">
              <HStack>
                <Button {...getDecrementButtonProps()}>-</Button>
                <Input {...getInputProps()} color="white" textAlign="center" />
                <Button {...getIncrementButtonProps()}>+</Button>
              </HStack>
            </Box>
          </HStack>
          <HStack width="100%" justifyContent="space-between">
            <Text color="white">방문 일자</Text>
            <Box>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={targetDate}
                onChange={(date) => date && handleDateChange(date)}
                customInput={<DateInput />}
                minDate={new Date()}
                maxDate={addMonths(new Date(), 3)}
                showDisabledMonthNavigation
              />
            </Box>
          </HStack>
          <HStack width="100%" justifyContent="space-between">
            <Text color="white">방문 시각</Text>
            <Box>
              <Select
                color="white"
                value={targetTime.text}
                onChange={(e) => handleTimeChange(e.target.value)}
              >
                {timeOptions
                  .map(({ text }) => text)
                  .map((v, i) => (
                    <option value={v} key={i}>
                      {v}
                    </option>
                  ))}
              </Select>
            </Box>
          </HStack>
          <HStack width="100%" justifyContent="space-between">
            <Text color="white">방문자 명</Text>
            <Box maxWidth="60%">
              <Input
                placeholder="name"
                bg="white"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
          </HStack>
          <VStack
            width="100%"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Text color="white">요청 사항</Text>
            <Box width="100%">
              <Textarea
                placeholder="comment"
                bg="white"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Box>
          </VStack>
          <Button
            marginTop="20px"
            width="100%"
            padding="12px"
            backgroundColor="white"
            borderRadius="md"
            onClick={handleSubmit}
          >
            예약 요청
          </Button>
          {reservationId && (
            <Button
              marginTop="20px"
              width="100%"
              padding="12px"
              backgroundColor="red"
              borderRadius="md"
              color="white"
              onClick={handleCancelation}
            >
              예약 취소
            </Button>
          )}
        </VStack>
      </Flex>
    </Container>
  );
};
