import { Timestamp } from 'firebase/firestore';

export enum ReservationStatus {
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface ReservationEntity {
  id: string;
  memberCount: number;
  phone: string;
  name: string;
  comment?: string;
  uid: string;
  targetDate: Timestamp;
  status?: ReservationStatus;
}

export class Reservation {
  id?: string;
  memberCount: number;
  phone: string;
  name: string;
  comment?: string;
  uid: string;
  targetDate: Date;
  targetTime: string;
  status: ReservationStatus;
  expired: boolean = false;

  constructor({
    memberCount,
    phone,
    name,
    comment,
    uid,
    targetDate,
    status,
  }: ReservationEntity) {
    this.memberCount = memberCount;
    this.phone = phone;
    this.name = name;
    this.comment = comment;
    this.uid = uid;
    this.status = status || ReservationStatus.WAITING;
    this.expired = targetDate.toDate() < new Date();

    const date = targetDate.toDate();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    this.targetDate = date;

    const hour = targetDate.toDate().getHours();
    let minutes = `${targetDate.toDate().getMinutes()}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    this.targetTime = `${hour}:${minutes}`;
  }

  setId(id: string) {
    this.id = id;
  }
}
