import * as React from 'react';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import theme from './theme';
import { Login } from './pages/Login';
import { Main } from './pages/Main';
import { ReservationPage } from './pages/Reservation';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './containers/Auth';
import { auth } from './providers/firebase';
import { User, onAuthStateChanged } from 'firebase/auth';
import { Admin } from './pages/Admin';
import { LoadingProvider } from './contexts/LoadingContext';

export const App = () => {
  const [user, setUser] = React.useState<null | User>(null);

  let vh = 0;

  React.useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
      }
    });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Flex
        textAlign="center"
        fontSize="xl"
        backgroundColor="#101727"
        maxW="640"
        width="100vw"
        minH={`calc(var(--vh, 1vh) * 100)`}
        height={`calc(var(--vh, 1vh) * 100)`}
        flexDirection="column"
        alignItems="center"
        margin="auto"
        padding="1.6rem"
        paddingBottom="0.8rem"
      >
        <LoadingProvider>
          {
            <>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <ProtectedRoute user={user}>
                        <Main />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/reservations"
                    element={
                      <ProtectedRoute user={user}>
                        <Main />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/reservations/all"
                    element={
                      <ProtectedRoute user={user}>
                        <Main overallStatus={true} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/reservations/new"
                    element={
                      <ProtectedRoute user={user}>
                        <ReservationPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/reservations/:reservationId"
                    element={
                      <ProtectedRoute user={user}>
                        <ReservationPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/admin" element={<Admin />} />
                </Routes>
              </BrowserRouter>
            </>
          }
        </LoadingProvider>
        {/* <VStack color="white" fontSize="xs" spacing={0}>
          <Box>사업자: 스튜디오 아룸 강남</Box>
          <Box>사업자 등록번호: 370-02-01839</Box>
          <Box>대표자: 이광호</Box>
          <Box>주소: 논현로85길 5-5 5층</Box>
        </VStack> */}
      </Flex>
    </ChakraProvider>
  );
};
