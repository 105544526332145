import {
  Badge,
  Box,
  Container,
  Heading,
  Stack,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Card, CardBody, CardHeader } from '@chakra-ui/card';
import { Image } from '@chakra-ui/image';
import { useEffect, useRef, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from '../providers/firebase';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { CalendarIcon, HamburgerIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Reservation,
  ReservationEntity,
  ReservationStatus,
} from '../types/reservation';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';

export const Main = ({ overallStatus }: { overallStatus?: boolean }) => {
  const nav = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const drawerRef = useRef<HTMLButtonElement | null>(null);

  const [reservations, setReservations] = useState<Reservation[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const ref = collection(db, 'reservations');
        const q = query(
          ref,
          overallStatus
            ? where('status', '==', ReservationStatus.ACCEPTED)
            : where('uid', '==', user.uid),
          orderBy('targetDate', 'desc')
        );
        const snapshot = await getDocs(q);

        const data: any[] = [];
        snapshot.forEach((doc) => {
          const reservation = new Reservation(doc.data() as ReservationEntity);
          reservation.setId(doc.id);
          data.push(reservation);
        });
        setReservations(data);
      }
    });
  }, [overallStatus]);

  const StatusBadge = ({ status }: { status: ReservationStatus }) => {
    let color = 'purple';
    switch (status) {
      case 'WAITING':
        color = 'purple';
        break;
      case 'ACCEPTED':
        color = 'green';
        break;
      case 'REJECTED':
        color = 'red';
        break;
    }

    return <Badge colorScheme={color}>{status}</Badge>;
  };

  return (
    <Container centerContent width="100%" height="100%" position="relative">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingLeft="20px"
        paddingRight="20px"
        paddingBottom="24px"
        width="100vw"
        maxW="640"
      >
        <IconButton
          // ref={drawerRef}
          onClick={onOpen}
          aria-label="menu"
          width="30px"
          icon={<HamburgerIcon />}
          colorScheme="transparent"
          fontSize="30"
        />
        <Box as="nav" maxW="200px">
          <Image src="logo.png" />
        </Box>
        <Box width="30px" />
      </Box>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <Button
              variant="ghost"
              width="100%"
              height={16}
              onClick={() => {
                onClose();
                nav('/reservations');
              }}
            >
              <HStack spacing={6} width="100%">
                <CalendarIcon fontSize={20} />
                <Text
                  variant="ghost"
                  cursor="pointer"
                  fontSize="20"
                  fontWeight={400}
                >
                  My Reservation
                </Text>
              </HStack>
            </Button>
            <Button
              variant="ghost"
              width="100%"
              height={16}
              onClick={() => {
                onClose();
                nav('/reservations/all');
              }}
            >
              <HStack spacing={6} width="100%">
                <InfoOutlineIcon fontSize={20} />
                <Text
                  variant="ghost"
                  cursor="pointer"
                  fontSize="20"
                  fontWeight={400}
                >
                  Reservation Status
                </Text>
              </HStack>
            </Button>
          </DrawerBody>
          <DrawerFooter>
            <Button
              colorScheme="gray"
              variant="link"
              onClick={() => {
                signOut(auth);
                window.location.reload();
              }}
            >
              Logout
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Box width="100%" textAlign="left" paddingBottom="12px">
        <Text color="white">
          {overallStatus ? 'RESERVATION STATUS' : 'MY RESERVATION'}
        </Text>
      </Box>
      <VStack overflowY="scroll" width="100%" flexGrow="1">
        {reservations.map((reservation, i) => (
          <Card
            key={i}
            variant="unstyled"
            width="100%"
            textAlign="start"
            direction={{ base: 'row' }}
            padding="20px"
            justifyContent="space-between"
            backgroundColor={reservation.expired ? 'gray' : 'white'}
          >
            <Stack>
              <CardHeader>
                <Heading size="xs">
                  {reservation.targetDate.getMonth() + 1}/
                  {reservation.targetDate.getDate()} (
                  {
                    ['월', '화', '수', '목', '금', '토', '일'][
                      reservation.targetDate.getDay() > 0
                        ? (reservation.targetDate.getDay() - 1) % 7
                        : 6
                    ]
                  }
                  )
                </Heading>
              </CardHeader>
              <CardBody>
                <Text fontSize="xs">
                  인원 - {reservation.name} 외 {reservation.memberCount - 1}명
                </Text>
                <Text fontSize="xs">방문 시각 - {reservation.targetTime}</Text>
              </CardBody>
            </Stack>
            {!overallStatus && (
              <CardBody flex="0">
                <VStack
                  height="100%"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  {!reservation.expired && (
                    <>
                      <StatusBadge status={reservation.status} />
                      <Button
                        size="sm"
                        onClick={() => nav(`/reservations/${reservation.id}`)}
                      >
                        예약 변경
                      </Button>
                    </>
                  )}
                </VStack>
              </CardBody>
            )}
          </Card>
        ))}
      </VStack>
      {!overallStatus && (
        <Button
          marginTop="20px"
          marginBottom="24px"
          width="100%"
          padding="12px"
          backgroundColor="white"
          borderRadius="md"
          onClick={() => nav('/reservations/new')}
        >
          예약하기
        </Button>
      )}
    </Container>
  );
};
